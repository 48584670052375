import styled from "styled-components";

export default styled.div`
	justify-content: center;
	align-items: center;

	background-color: black;
	color: #fff;
	margin: 15px;
`;
