import React from "react";

const Socalmedia = () => {
	return (
		<div>
			<div id='fixed-social'>
				<div>
					<a
						href='https://www.facebook.com/people/Rajput-Motorss/pfbid0S17E7L8PXgeMA1hq83jxgHQD39EShL4fpcKXDkjBAvqSgpaD1aQxqh3hgAq3m9Cvl/'
						class='fixed-facebook'
						target='_blank'>
						<i class='fa fa-facebook'></i>
					</a>
				</div>

				<div>
					<a href='' class='fixed-instagrem inst2' target='_blank'>
						<i class='fa fa-instagram'></i>
					</a>
				</div>

				<div>
					<a href='tel:9871888833' class='fixed-twitter'>
						<i class='fa fa-phone'></i>{" "}
					</a>
				</div>

				<div>
					<a
						href='https://api.whatsapp.com/send/?phone=9871888833&text= Hello Rajput Motors+Team%2C+I+would+like+to+know+more&type=phone_number&app_absent=0'
						class='fixed-twitter wtsp1'
						target='_blank'>
						<i class='fa fa-whatsapp fa-2x'></i>{" "}
					</a>
				</div>
			</div>
		</div>
	);
};

export default Socalmedia;
